export const toolTips = {
    productEnabled:
        'Se não estiver habilitado, qualquer solicitação será considerada fora da política',
    approvalFlow:
        'Definir quantos níveis de aprovação serão necessários (de acordo com o nível de aprovação de cada aprovador), se nível 1, então não será necessária aprovação de aprovadores de nível 2 e assim por diante.',
    maxPriceAllowed:
        'Defina se o valor máximo para solicitações deve ser fixo ou se deve variar de acordo com a disponibilidade e preços da data. Valores fora do percentual ou acima do máximo serão considerados fora da política.',
    bestBrandAllowed:
        'Solicitações de cabines melhores do que a permitida serão consideradas fora da política.',
    extraBags:
        'Se não for permitido, solicitações com bagagem serão consideradas fora da política. Se for permitido, serão consideradas fora da política bagagens com valor superior ao valor estipulado.',
    minDaysInAdvance:
        'Solicitações feitas com antecedencia menor do que o valor definido serão consideradas fora da política.',
    onlyRefundableFares:
        'Solicitações que são reembolsáveis serão consideradas fora da política.',
    maxNumberOfNights:
        'Solicitações de periodos maiores do que o definido serão consideradas fora da política.',
    allowExtras:
        'Se não for permitido, solicitações com adicionais serão consideradas fora da política. Se for permitido, serão consideradas fora da política adicionais que totalizem valor superior ao valor estipulado.',
    maxNumberOfDays:
        'Solicitações de periodos maiores do que o definido serão consideradas fora da política.',
}
