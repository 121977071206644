/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { MdListAlt } from 'react-icons/md'
import ModalContainer from 'react-modal'
import { toast } from 'react-toastify'
import crypto from 'crypto'
import { Button } from '../../components/Button'
import { Input } from '../../UnformInputs/Input'
import { getValidationErrors } from '../../utils/getValidationErrors'
import {
    CostCentersLinkedContainer,
    EditPaymentMethodContainer,
    OfficeHandlerContainer,
    PaymentMethodInfo,
    PaymentMethodInfoUsedInContainer,
    UsersLinkedContainer,
} from './styles'
import { useCompany } from '../../hooks/use-company'
import { PaymentMethod } from '../../entities/payment-method'
import { ControlButton } from '../../components/ControlButton'
import { usePayment } from '../../hooks/use-payment'
import { PaymentMethodType } from '../../enums/payment-method-type.enum'
import { CreatePaymentMethodDto } from '../../dtos/create-payment-method'
import { DocumentType } from '../../enums/document-type.enum'
import {
    cpfFormatter,
    creditCardNumberFormatter,
    formatExpiry,
} from '../../utils/maskFunctions'
import { InfoModal } from '../InfoModal'
import { SimpleInput } from '../../components/SimpleInput'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    paymentMethod?: PaymentMethod
}

export function PaymentMethodHandler({
    isOpen,
    onRequestClose,
    paymentMethod,
}: Props) {
    // hooks
    const { getMembers, members, company } = useCompany()
    const {
        elementToAddSettingsHandler,
        editPaymentMethod,
        createPaymentMethod,
        paymentMethods,
        deletePaymentMethod,
    } = usePayment()

    // ref
    const formRef = useRef<FormHandles>(null)
    // state
    const [label, setLabel] = useState<string>(paymentMethod?.label || '')
    const [busyBtn, setBusyBtn] = useState(false)
    const [leaveFirstStep, setLeaveFirstStep] = useState(() => {
        if (paymentMethod) {
            return true
        }
        return false
    })
    const [paymentConfig, setPaymentConfig] = useState<string[]>(() => {
        const types = []
        if (paymentMethod) {
            if (paymentMethod.companyInfo.isGlobal) {
                types.push('ISGLOBAL')
            }
            if (paymentMethod.companyInfo.canBeUsedOnExpenses) {
                types.push('canBeUsedOnExpenses')
            }
            paymentMethod.companyInfo.productTypes.forEach((type) => {
                types.push(type)
            })
        }
        return types
    })
    const [number, setNumber] = useState<string>()
    const [expiry, setExpiry] = useState<string>()
    const [document, setDocument] = useState<string>()
    const key = `${'-----BEGIN PUBLIC KEY-----\n'}${
        process.env.REACT_APP_PUBLIC_KEY
    }${'-----END PUBLIC KEY-----'}`
    const [payment, setPayment] = useState<PaymentMethod | undefined>(
        paymentMethod
    )

    const [openDeletePayment, setOpenDeletePayment] = useState(false)

    useEffect(() => {
        ;(async () => {
            if (company) {
                await getMembers(company.id)
            }
        })()
    }, [])

    useEffect(() => {
        setPayment(paymentMethods.find((p) => p.id === paymentMethod?.id))
    }, [paymentMethods])

    async function handleCreatePaymentMethod(data: {
        name: string
        cvc: number
        expiry: string
        number: number
        document: string
        label: string
    }) {
        setBusyBtn(true)
        try {
            formRef.current?.setErrors({})
            const [month, year] = data.expiry.split('/')
            const currentYear = new Date(
                Number(`20${year}`),
                Number(`${Number(month) - 1}`)
            )
            if (currentYear < new Date()) {
                return toast.warning('Cartão com data expirada')
            }
            if (month.length !== 2) {
                return toast.warning(
                    'Mês inválido na data de vencimento do cartão'
                )
            }
            if (Number(month) > 12) {
                return toast.warning(
                    'Mês inválido na data de vencimento do cartão'
                )
            }
            if (year.length !== 2) {
                return toast.warning(
                    'Coloque o ano completo na data de vencimento do cartão'
                )
            }

            const hash = crypto
                .publicEncrypt(
                    {
                        key,
                        padding: crypto.constants.RSA_PKCS1_PADDING,
                        passphrase: '1234',
                    },
                    Buffer.from(
                        JSON.stringify({
                            number: number?.replaceAll(' ', ''),
                            code: data.cvc,
                            expiry,
                        })
                    )
                )
                .toString('base64')
            const createPaymentData: CreatePaymentMethodDto = {
                type: PaymentMethodType.CREDIT_CARD,
                label: data.label,
                companyId: company?.id,
                creditCard: {
                    hash,
                    holder: {
                        document: {
                            type: DocumentType.CPF,
                            value: cpfFormatter(document || '', 'TO_API'),
                        },
                        name: data.name,
                    },
                },
            }
            await createPaymentMethod(createPaymentData)
            setBusyBtn(false)
            onRequestClose()
        } catch (error) {
            setBusyBtn(false)
            toast.info(
                'Verifique se você preencheu todos os campos corretamente'
            )
            if (error instanceof Yup.ValidationError) {
                const errors = getValidationErrors(error)
                formRef.current?.setErrors(errors)
            }
        }
    }

    async function handleEditPaymentMethod() {
        if (paymentMethod) {
            const paymentConfigData: {
                isGlobal: boolean
                canBeUsedOnExpenses: boolean
                productTypes: string[]
                label?: string
            } = {
                isGlobal:
                    paymentConfig.filter((t) => t === 'ISGLOBAL').length > 0,
                canBeUsedOnExpenses:
                    paymentConfig.filter((t) => t === 'canBeUsedOnExpenses')
                        .length > 0,
                productTypes: [],
            }

            paymentConfig
                .filter(
                    (type) =>
                        type !== 'ISGLOBAL' && type !== 'canBeUsedOnExpenses'
                )
                .forEach((t) => {
                    paymentConfigData.productTypes.push(t)
                })
            const response = await editPaymentMethod(
                {
                    label: label === '' ? undefined : label,
                    companyInfo: {
                        isGlobal: paymentConfigData.isGlobal,
                        canBeUsedOnExpenses:
                            paymentConfigData.canBeUsedOnExpenses,
                        productTypes:
                            paymentConfigData.productTypes as unknown as [
                                'CAR' | 'HOTEL' | 'FLIGHT' | 'BUS'
                            ],
                    },
                },
                paymentMethod.id
            )
            if (response) {
                toast.success('Forma de pagamento editada com sucesso')
                onRequestClose()
            }
        }
    }

    function returnLinkedLabel(list: string[]) {
        if (list.length === 0) {
            return 'Não existem viajantes vinculados'
        }
        if (list.length <= 2) {
            return `${list.map((t) => t).join(', ')}`
        }
        return `${list[0]}, ${list[1]}... +${list.length - 2}`
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content"
        >
            {!leaveFirstStep && (
                <Form ref={formRef} onSubmit={handleCreatePaymentMethod}>
                    <div className="modal-header">
                        <div className="modal-header-title">
                            <MdListAlt />
                            <h1 className="f22-700-dark">Novo Cartão</h1>
                        </div>
                        <div className="modal-header-description">
                            <div className="modal-header-description--blank" />
                            <p className="f16-500-gray">
                                Cadastre a forma de pagamento abaixo.
                            </p>
                        </div>
                    </div>
                    <button
                        className="close"
                        type="button"
                        onClick={() => onRequestClose()}
                    >
                        X
                    </button>
                    <OfficeHandlerContainer>
                        <div className="modal-content">
                            <div className="title">
                                <h1 className="f16-500-gray-secondary">
                                    Dados do cartão
                                </h1>
                            </div>

                            <div className="form">
                                <Input
                                    label="Número do cartão"
                                    name="number"
                                    value={number}
                                    onKeyPress={(e) => {
                                        if (/[a-zA-Z]/.test(e.key)) {
                                            e.preventDefault()
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (
                                            ((e.target.value.toString()[0] +
                                                e.target.value.toString()[1] ===
                                                '37' ||
                                                e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] ===
                                                    '34') &&
                                                e.target.value
                                                    .toString()
                                                    .replaceAll(' ', '')
                                                    .length <= 15) ||
                                            (e.target.value.toString()[0] +
                                                e.target.value.toString()[1] !==
                                                '37' &&
                                                e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] !==
                                                    '34' &&
                                                e.target.value.toString()[0] +
                                                    e.target.value.toString()[1] !==
                                                    '36' &&
                                                e.target.value
                                                    .toString()
                                                    .replaceAll(' ', '')
                                                    .length <= 19) ||
                                            (e.target.value.toString()[0] +
                                                e.target.value.toString()[1] ===
                                                '36' &&
                                                e.target.value
                                                    .toString()
                                                    .replaceAll(' ', '')
                                                    .length <= 14)
                                        ) {
                                            setNumber(
                                                creditCardNumberFormatter(
                                                    e.target.value || ''
                                                )
                                            )
                                        }
                                    }}
                                />
                                <div className="form--grid">
                                    <Input
                                        label="Data de expiração"
                                        name="expiry"
                                        value={expiry}
                                        onKeyPress={(e) => {
                                            if (/[a-zA-Z]/.test(e.key)) {
                                                e.preventDefault()
                                            }
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 5) {
                                                setExpiry(
                                                    formatExpiry(e.target.value)
                                                )
                                            }
                                        }}
                                    />
                                    <Input
                                        label="Código de segurança"
                                        name="cvc"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-content">
                            <div className="title">
                                <h1 className="f16-500-gray-secondary">
                                    Informações do titutlar do cartão
                                </h1>
                            </div>

                            <div className="form">
                                <Input
                                    label="Nome do titular do cartão"
                                    name="name"
                                />
                                <div className="form--grid">
                                    <Input label="Identificador" name="label" />
                                    <Input
                                        label="CPF"
                                        name="document"
                                        value={document}
                                        onChange={(e) => {
                                            if (e) {
                                                setDocument(
                                                    cpfFormatter(
                                                        e.target.value,
                                                        'INPUT'
                                                    )
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </OfficeHandlerContainer>
                    <div className="modal-confirm-btn">
                        <Button
                            color="PRIMARY"
                            buttonType="FILLED"
                            text="Cadastrar cartão"
                            busy={busyBtn}
                            type="submit"
                        />
                    </div>
                </Form>
            )}
            {leaveFirstStep && paymentMethod && payment && (
                <EditPaymentMethodContainer>
                    <div className="modal-header">
                        <div className="modal-header-title">
                            <MdListAlt />
                            {payment.type === PaymentMethodType.CREDIT_CARD ? (
                                <h1 className="f22-700-dark">{`Cartão com final ${paymentMethod?.creditCard?.lastFourDigits}`}</h1>
                            ) : (
                                <h1 className="f22-700-dark">Faturado</h1>
                            )}
                        </div>
                        {payment.type === PaymentMethodType.CREDIT_CARD && (
                            <div className="modal-header-description">
                                <div className="modal-header-description--blank" />
                                <p className="f16-500-gray">
                                    Dê um apelido para a forma de pagamento e
                                    vincule ela aos seus usuários e estruturas
                                    gerenciais..
                                </p>
                            </div>
                        )}
                    </div>
                    <button
                        className="close"
                        type="button"
                        onClick={() => onRequestClose()}
                    >
                        X
                    </button>
                    {payment.type === PaymentMethodType.CREDIT_CARD && (
                        <PaymentMethodInfo>
                            <div className="modal-content">
                                <div className="title">
                                    <h1 className="f16-500-gray-secondary">
                                        Dados do cartão
                                    </h1>
                                </div>
                                <div className="grid">
                                    <div className="label-value">
                                        <h1 className="label f16-500-gray">
                                            Número
                                        </h1>
                                        <h2 className="value f16-500-dark">{`●●●● ●●●● ●●●● ${paymentMethod?.creditCard?.lastFourDigits}`}</h2>
                                    </div>
                                    <div className="label-value">
                                        <h1 className="label f16-500-gray">
                                            Data de expiração
                                        </h1>
                                        <h2 className="value f16-500-dark">
                                            {payment.creditCard
                                                .expirationDate ?? '-'}
                                        </h2>
                                    </div>
                                    <div className="label-value">
                                        <h1 className="label f16-500-gray">
                                            Titular do cartão
                                        </h1>
                                        <h2 className="value f16-500-dark">
                                            {
                                                paymentMethod?.creditCard
                                                    ?.holder.name
                                            }
                                        </h2>
                                    </div>
                                    <div className="label-value">
                                        <h1 className="label f16-500-gray">
                                            Identificador
                                        </h1>
                                        <SimpleInput
                                            label="Identificator"
                                            name="label"
                                            value={label}
                                            onChange={(e) =>
                                                setLabel(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </PaymentMethodInfo>
                    )}
                    <PaymentMethodInfoUsedInContainer>
                        <div className="modal-content">
                            <div className="title">
                                <h1 className="f16-500-gray-secondary">
                                    Configurações do pagamento
                                </h1>
                            </div>
                            <div className="payment-type-used">
                                <div className="select-type">
                                    <h1 className="f16-500-gray">
                                        Passagem aéreas
                                    </h1>
                                    <ControlButton
                                        type="SWITCH"
                                        isPressed={
                                            !!paymentConfig.find(
                                                (t) => t === 'FLIGHT'
                                            )
                                        }
                                        id={`flight-${paymentMethod?.id}`}
                                        callback={() => {
                                            if (
                                                paymentConfig.find(
                                                    (t) => t === 'FLIGHT'
                                                )
                                            ) {
                                                setPaymentConfig(
                                                    (prevState) => {
                                                        prevState =
                                                            prevState.filter(
                                                                (type) =>
                                                                    type !==
                                                                    'FLIGHT'
                                                            )
                                                        return prevState
                                                    }
                                                )
                                            } else {
                                                setPaymentConfig(
                                                    (prevState) => [
                                                        ...prevState,
                                                        'FLIGHT',
                                                    ]
                                                )
                                            }
                                        }}
                                    />
                                </div>
                                <div className="select-type">
                                    <h1 className="f16-500-gray">Hospedagem</h1>
                                    <ControlButton
                                        type="SWITCH"
                                        isPressed={
                                            !!paymentConfig.find(
                                                (t) => t === 'HOTEL'
                                            )
                                        }
                                        id={`hotel-${paymentMethod?.id}`}
                                        callback={() => {
                                            if (
                                                paymentConfig.find(
                                                    (t) => t === 'HOTEL'
                                                )
                                            ) {
                                                setPaymentConfig(
                                                    (prevState) => {
                                                        prevState =
                                                            prevState.filter(
                                                                (type) =>
                                                                    type !==
                                                                    'HOTEL'
                                                            )
                                                        return prevState
                                                    }
                                                )
                                            } else {
                                                setPaymentConfig(
                                                    (prevState) => [
                                                        ...prevState,
                                                        'HOTEL',
                                                    ]
                                                )
                                            }
                                        }}
                                    />
                                </div>
                                <div className="select-type">
                                    <h1 className="f16-500-gray">
                                        Locação de carro
                                    </h1>
                                    <ControlButton
                                        type="SWITCH"
                                        isPressed={
                                            !!paymentConfig.find(
                                                (t) => t === 'CAR'
                                            )
                                        }
                                        id={`car-${paymentMethod?.id}`}
                                        callback={() => {
                                            if (
                                                paymentConfig.find(
                                                    (t) => t === 'CAR'
                                                )
                                            ) {
                                                setPaymentConfig(
                                                    (prevState) => {
                                                        prevState =
                                                            prevState.filter(
                                                                (type) =>
                                                                    type !==
                                                                    'CAR'
                                                            )
                                                        return prevState
                                                    }
                                                )
                                            } else {
                                                setPaymentConfig(
                                                    (prevState) => [
                                                        ...prevState,
                                                        'CAR',
                                                    ]
                                                )
                                            }
                                        }}
                                    />
                                </div>
                                {payment.type ===
                                    PaymentMethodType.CREDIT_CARD && (
                                    <div className="select-type">
                                        <h1 className="f16-500-gray">
                                            Despesas
                                        </h1>
                                        <ControlButton
                                            type="SWITCH"
                                            isPressed={
                                                !!paymentConfig.find(
                                                    (t) =>
                                                        t ===
                                                        'canBeUsedOnExpenses'
                                                )
                                            }
                                            id={`expense-${paymentMethod?.id}`}
                                            callback={() => {
                                                if (
                                                    paymentConfig.find(
                                                        (t) =>
                                                            t ===
                                                            'canBeUsedOnExpenses'
                                                    )
                                                ) {
                                                    setPaymentConfig(
                                                        (prevState) => {
                                                            prevState =
                                                                prevState.filter(
                                                                    (type) =>
                                                                        type !==
                                                                        'canBeUsedOnExpenses'
                                                                )
                                                            return prevState
                                                        }
                                                    )
                                                } else {
                                                    setPaymentConfig(
                                                        (prevState) => [
                                                            ...prevState,
                                                            'canBeUsedOnExpenses',
                                                        ]
                                                    )
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                <div className="select-type">
                                    <h1 className="f16-500-gray">
                                        Pode ser utilizado por todos os
                                        viajantes da empresa
                                    </h1>
                                    <ControlButton
                                        type="SWITCH"
                                        isPressed={
                                            !!paymentConfig.find(
                                                (t) => t === 'ISGLOBAL'
                                            )
                                        }
                                        id="ISGLOBAL"
                                        callback={() => {
                                            if (
                                                paymentConfig.find(
                                                    (t) => t === 'ISGLOBAL'
                                                )
                                            ) {
                                                setPaymentConfig(
                                                    (prevState) => {
                                                        prevState =
                                                            prevState.filter(
                                                                (type) =>
                                                                    type !==
                                                                    'ISGLOBAL'
                                                            )
                                                        return prevState
                                                    }
                                                )
                                            } else {
                                                setPaymentConfig(
                                                    (prevState) => [
                                                        ...prevState,
                                                        'ISGLOBAL',
                                                    ]
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </PaymentMethodInfoUsedInContainer>
                    <UsersLinkedContainer>
                        <div className="modal-content">
                            <div className="title">
                                <h1 className="f16-500-gray-secondary">
                                    Usuários vinculados à esta forma de
                                    pagamento
                                </h1>
                            </div>
                            <div className="row-button">
                                <h1 className="f16-500-gray">
                                    {returnLinkedLabel(
                                        payment.companyInfo.userIds.map(
                                            (userId) =>
                                                `${
                                                    members.find(
                                                        (member) =>
                                                            member.id === userId
                                                    )?.firstName
                                                } ${
                                                    members.find(
                                                        (member) =>
                                                            member.id === userId
                                                    )?.lastName || ''
                                                }`
                                        )
                                    )}
                                </h1>
                                <Button
                                    color="PRIMARY"
                                    buttonType="TEXT"
                                    text="Vincular"
                                    onClick={() =>
                                        elementToAddSettingsHandler({
                                            id: payment.id,
                                            type: 'ADD_USER_TO_PAYMENT',
                                            list: payment.companyInfo.userIds.map(
                                                (userId) => {
                                                    return {
                                                        id: userId,
                                                        label: `${
                                                            members.find(
                                                                (member) =>
                                                                    member.id ===
                                                                    userId
                                                            )?.firstName || ''
                                                        } ${
                                                            members.find(
                                                                (member) =>
                                                                    member.id ===
                                                                    userId
                                                            )?.lastName || ''
                                                        }`,
                                                        url: members.find(
                                                            (member) =>
                                                                member.id ===
                                                                userId
                                                        )?.photo
                                                            ? members.find(
                                                                  (member) =>
                                                                      member.id ===
                                                                      userId
                                                              )?.photo?.url
                                                            : undefined,
                                                    }
                                                }
                                            ),
                                            listToAdd: members.map((member) => {
                                                return {
                                                    id: member.id,
                                                    label: `${
                                                        member.firstName
                                                    } ${member.lastName || ''}`,
                                                    url: member.photo
                                                        ? member.photo.url
                                                        : undefined,
                                                }
                                            }),
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </UsersLinkedContainer>
                    <CostCentersLinkedContainer>
                        <div className="modal-content">
                            <div className="title">
                                <h1 className="f16-500-gray-secondary">
                                    Informações Gerenciais vinculados à esta
                                    forma de pagamento
                                </h1>
                            </div>
                            {company?.managementInfo.map((management) => {
                                return (
                                    <div className="row-button">
                                        <div className="info-row">
                                            <h1 className="f16-500-dark">
                                                {management.label}
                                            </h1>
                                            <h1 className="f16-500-gray">
                                                {returnLinkedLabel(
                                                    payment.companyInfo.managementInfo
                                                        .find(
                                                            (m) =>
                                                                m.id ===
                                                                management.id
                                                        )
                                                        ?.options.map(
                                                            (option) =>
                                                                option.label
                                                        ) || []
                                                )}
                                            </h1>
                                        </div>
                                        <Button
                                            color="PRIMARY"
                                            buttonType="TEXT"
                                            text="Vincular"
                                            onClick={() => {
                                                elementToAddSettingsHandler({
                                                    paymentMethod,
                                                    id: management.id,
                                                    type: 'ADD_OPTION_TO_PAYMENT',
                                                    listToAdd:
                                                        management.options.map(
                                                            (option) => {
                                                                return {
                                                                    id: option.id,
                                                                    label: option.label,
                                                                }
                                                            }
                                                        ),
                                                    list:
                                                        payment.companyInfo.managementInfo
                                                            .find(
                                                                (m) =>
                                                                    m.id ===
                                                                    management.id
                                                            )
                                                            ?.options.map(
                                                                (option) => {
                                                                    return {
                                                                        id: option.id,
                                                                        label: option.label,
                                                                    }
                                                                }
                                                            ) || [],
                                                })
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </CostCentersLinkedContainer>
                    <div className="action-btns">
                        <div className="action-btn">
                            <Button
                                color="PRIMARY"
                                buttonType="BORDERED"
                                text="Deletar"
                                onClick={() => {
                                    setOpenDeletePayment(true)
                                }}
                            />
                        </div>
                        <div className="action-btn">
                            <Button
                                color="PRIMARY"
                                buttonType="FILLED"
                                text="Salvar"
                                onClick={() => handleEditPaymentMethod()}
                            />
                        </div>
                    </div>
                </EditPaymentMethodContainer>
            )}
            <InfoModal
                isOpen={openDeletePayment}
                onRequestClose={() => setOpenDeletePayment(false)}
                onOk={async () => {
                    const response = await deletePaymentMethod(
                        paymentMethod?.id || ''
                    )
                    if (response) {
                        toast.success('Forma de pagamento deletada com sucesso')
                        setOpenDeletePayment(false)
                        onRequestClose()
                    }
                }}
                title="Deletar forma de pagamento"
                description="Você tem certeza que deseja deletar essa forma de pagamento?"
            />
        </ModalContainer>
    )
}
