/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import { FiPlusCircle, FiTrash } from 'react-icons/fi'
import { MdCorporateFare, MdLocationPin } from 'react-icons/md'
import { v4 } from 'uuid'
// import { Button } from '../../../../components/Button'
import { toast } from 'react-toastify'
import { InnerHeader } from '../../../../components/InnerHeader'
import { useCompany } from '../../../../hooks/use-company'
import { OfficeHandler } from '../../../../modals/OfficeHandler'
import { Office, OfficeInfoContainer } from './styles'
import { GeneralSubPageContent } from '../styles'
import { Button } from '../../../../components/Button'
import { InfoModal } from '../../../../modals/InfoModal'

export function OfficeInfo() {
    // hooks
    const { company, removeOffice } = useCompany()
    // state
    const [openOfficeHandler, setOpenOfficeHandler] = useState(false)
    const [openRemoveOfficeHandler, setOpenRemoveOfficeHandler] =
        useState<number>()

    return (
        <GeneralSubPageContent>
            <OfficeInfoContainer>
                <InnerHeader
                    title="Escritórios"
                    callback={() => {}}
                    icon={MdCorporateFare}
                />
                {company?.officeAddresses.map((office, index) => {
                    return (
                        <Office key={v4()}>
                            <div className="content">
                                <div className="title">
                                    <h1>
                                        {index === 0
                                            ? 'Principal'
                                            : `Unidade de ${office.city}`}
                                    </h1>
                                    {index === 0 && (
                                        <p>(endereço da empresa)</p>
                                    )}
                                </div>
                                <div className="description">
                                    <div className="info">
                                        <MdLocationPin />
                                        <div className="info-labels">
                                            <h1>{`${office.city}, ${office.state} - ${office.zipCode}`}</h1>
                                            <h2>{`${office.street}, ${office.number}`}</h2>
                                        </div>
                                    </div>
                                    {index === 0 ? (
                                        <div className="blank" />
                                    ) : (
                                        <div className="edit-btn">
                                            {/* <Button
                                            buttonType="TEXT"
                                            color="PRIMARY"
                                            text="Editar"
                                        /> */}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {index !== 0 ? (
                                <Button
                                    className="delete-btn"
                                    buttonType="TEXT"
                                    color="PRIMARY"
                                    onClick={() =>
                                        setOpenRemoveOfficeHandler(index)
                                    }
                                    text="Remover"
                                    icon={FiTrash}
                                />
                            ) : (
                                <div />
                            )}
                        </Office>
                    )
                })}
                <button
                    type="button"
                    className="add-officer"
                    onClick={() => setOpenOfficeHandler(true)}
                >
                    <FiPlusCircle />
                    <h2>Cadastrar outro escritório</h2>
                </button>
                <OfficeHandler
                    isOpen={openOfficeHandler}
                    onRequestClose={() => setOpenOfficeHandler(false)}
                />
                {!!openRemoveOfficeHandler && (
                    <InfoModal
                        isOpen={!!openRemoveOfficeHandler}
                        onRequestClose={() =>
                            setOpenRemoveOfficeHandler(undefined)
                        }
                        title="Remover escritório"
                        description="Você tem certeza que deseja remover esse escritório?"
                        onOk={async () => {
                            const response = await removeOffice(
                                openRemoveOfficeHandler
                            )
                            if (response) {
                                toast.success('Escritório removido com sucesso')
                            }
                            setOpenRemoveOfficeHandler(undefined)
                        }}
                    />
                )}
            </OfficeInfoContainer>
        </GeneralSubPageContent>
    )
}
