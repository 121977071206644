/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import React from 'react'
import ModalContainer from 'react-modal'
import { Button } from '../../components/Button'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    onOk: () => void
    title: string
    description: string
    children?: React.ReactNode
}

export function InfoModal({
    isOpen,
    description,
    title,
    children,
    onRequestClose,
    onOk,
}: Props) {
    const [loading, setLoading] = React.useState(false)

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-content-warning"
        >
            <button
                className="close"
                type="button"
                onClick={() => onRequestClose()}
            >
                X
            </button>
            <div className="react-modal-content-warning--title">
                <h1 className="f22-700-dark">{title}</h1>
            </div>
            <div className="react-modal-content-warning--description">
                <p className="f16-500-gray">{description}</p>
                {children && children}
            </div>
            <div className="react-modal-content-warning--button">
                <Button
                    buttonType="TEXT"
                    color="PRIMARY"
                    text="Cancelar"
                    onClick={() => onRequestClose()}
                />
                <Button
                    buttonType="FILLED"
                    color="PRIMARY"
                    text="Confirmar"
                    busy={loading}
                    onClick={() => {
                        setLoading(true)
                        onOk()
                        setLoading(false)
                    }}
                />
            </div>
        </ModalContainer>
    )
}
