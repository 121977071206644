/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { MdHotel } from 'react-icons/md'
import { ControlButton } from '../../../components/ControlButton'
import { SelectInput } from '../../../components/SelectInput'
import { SimpleInput } from '../../../components/SimpleInput'
import { TitleDescription } from '../../../components/TitleDescription'
import { useCompany } from '../../../hooks/use-company'
import { ApprovalFlowSetting } from '../../../interfaces/traveler-clearance/approval-flow-setting'
import { PriceType } from '../../../interfaces/traveler-clearance/price-type'
import { HotelPolicy } from '../../../interfaces/traveler-clearance/traveler-clearance/polices/hotel-policy'
import {
    PriceTypeLabel,
    ReturnApprovalFlowLabel,
    ReturnApprovalLevelLabel,
} from '../../../utils/users/functions'
import { DropDownPolitic } from '../headerPolicy'
import { SinglePolicieContainer } from './styles'
import { toolTips } from '../data/labels'

interface Props {
    internationalHotel: HotelPolicy['international']
}

export function InternationalHotelPolicy({ internationalHotel }: Props) {
    const { selectTravelerClearance, editTravelerClearance, company } =
        useCompany()

    const approvalLevelOptions: {
        label: string
        value: number
    }[] = new Array(company?.maxApprovalLevel || 1).fill(1).map((_, i) => {
        return {
            label: `Nivel ${i + 1}`,
            value: i + 1,
        }
    })

    const [interHotel, setInterHotel] =
        useState<HotelPolicy['international']>(internationalHotel)

    useEffect(() => {
        setInterHotel(internationalHotel)
    }, [selectTravelerClearance])

    return (
        <DropDownPolitic
            title="Hotéis Internacionais"
            description="Hotéis que fogem das regras configuradas abaixo serão considerados como fora da política de viagens."
            icon={MdHotel}
            callback={async () => {
                await editTravelerClearance(selectTravelerClearance!.id, {
                    hotel: { international: interHotel },
                })
            }}
        >
            <SinglePolicieContainer>
                <TitleDescription title="Permitir fazer solicitações de hotéis internacionais?" />
                <div className="inputs">
                    <ControlButton
                        id="interHotel.productEnabled"
                        type="SWITCH"
                        isPressed={interHotel.productEnabled}
                        callback={() => {
                            setInterHotel((prevState) => {
                                return {
                                    ...prevState,
                                    productEnabled: !prevState.productEnabled,
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Fluxo de aprovação"
                    description="Defina quando um hotel precisa ser aprovado e quantos níveis de aprovação devem existir."
                    info={toolTips.approvalFlow}
                />
                <div className="inputs">
                    {/* <SelectInput
                        name="a"
                        value={{
                            label: ReturnApprovalFlowLabel(
                                interHotel.approvalFlow.setting
                            ),
                            value: interHotel.approvalFlow.setting,
                        }}
                        options={[
                            {
                                label: 'Sempre',
                                value: ApprovalFlowSetting.ALWAYS,
                            },
                            {
                                label: 'Dentro da política',
                                value: ApprovalFlowSetting.INSIDE_POLICY,
                            },
                            {
                                label: 'Nunca',
                                value: ApprovalFlowSetting.NEVER,
                            },
                        ]}
                        onChange={(e) => {
                            if (e) {
                                setInterHotel((prevState) => {
                                    return {
                                        ...prevState,
                                        approvalFlow: {
                                            ...prevState.approvalFlow,
                                            setting: e.value,
                                        },
                                    }
                                })
                            }
                        }}
                    /> */}
                    <div className="label-input">
                        <p>Defina a quantidade de níveis</p>
                        <SelectInput
                            name="a"
                            value={{
                                label: ReturnApprovalLevelLabel(
                                    interHotel.approvalFlow
                                        .requiredApprovalLevel
                                ),
                                value: interHotel.approvalFlow
                                    .requiredApprovalLevel,
                            }}
                            options={approvalLevelOptions}
                            onChange={(e) => {
                                if (e) {
                                    setInterHotel((prevState) => {
                                        return {
                                            ...prevState,
                                            approvalFlow: {
                                                ...prevState.approvalFlow,
                                                requiredApprovalLevel: e.value,
                                            },
                                        }
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Preços dinâmicos ou valor máximo"
                    description="Defina um percentual máximo acima do valor mediano."
                    info={toolTips.maxPriceAllowed}
                />
                <div className="inputs">
                    <SelectInput
                        name="a"
                        value={{
                            label: PriceTypeLabel(
                                interHotel.defaultRules.maxPricePerNight.type
                            ),
                            value: interHotel.defaultRules.maxPricePerNight
                                .type,
                        }}
                        options={[
                            {
                                label: 'Preços dinâmicos',
                                value: PriceType.PERCENTAGE_ABOVE_MEDIAN,
                            },
                            {
                                label: 'Valor Máximo',
                                value: PriceType.FIXED_PRICE,
                            },
                        ]}
                        onChange={(e) => {
                            if (e) {
                                setInterHotel((prevState) => {
                                    return {
                                        ...prevState,
                                        defaultRules: {
                                            ...prevState.defaultRules,
                                            maxPricePerNight: {
                                                ...prevState.defaultRules
                                                    .maxPricePerNight,
                                                value: 0,
                                                type: e.value,
                                            },
                                        },
                                    }
                                })
                            }
                        }}
                    />
                    <SimpleInput
                        label=""
                        name="a"
                        type="number"
                        isPercentage={
                            interHotel.defaultRules.maxPricePerNight.type ===
                            PriceType.PERCENTAGE_ABOVE_MEDIAN
                        }
                        isPricing={
                            interHotel.defaultRules.maxPricePerNight.type ===
                            PriceType.FIXED_PRICE
                        }
                        value={interHotel.defaultRules.maxPricePerNight.value}
                        onChange={(e) =>
                            setInterHotel((prevState) => {
                                return {
                                    ...prevState,
                                    defaultRules: {
                                        ...prevState.defaultRules,
                                        maxPricePerNight: {
                                            ...prevState.defaultRules
                                                .maxPricePerNight,
                                            value: Number(e.target.value),
                                        },
                                    },
                                }
                            })
                        }
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Antecedência Mínima"
                    description="Defina uma antecedência mínima em dias para a reserva."
                    info={toolTips.minDaysInAdvance}
                />
                <div className="inputs">
                    <SimpleInput
                        label=""
                        type="number"
                        name="a"
                        value={interHotel.defaultRules.minDaysInAdvance}
                        onChange={(e) =>
                            setInterHotel((prevState) => {
                                return {
                                    ...prevState,
                                    defaultRules: {
                                        ...prevState.defaultRules,
                                        minDaysInAdvance: Number(
                                            e.target.value
                                        ),
                                    },
                                }
                            })
                        }
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription
                    title="Número máximo de noites"
                    description="Defina um número máximo de noites para hotéis internacionais."
                    info={toolTips.maxNumberOfNights}
                />
                <div className="inputs">
                    <SimpleInput
                        label=""
                        name="a"
                        type="number"
                        value={interHotel.defaultRules.maxNumberOfNights}
                        onChange={(e) =>
                            setInterHotel((prevState) => {
                                return {
                                    ...prevState,
                                    defaultRules: {
                                        ...prevState.defaultRules,
                                        maxNumberOfNights: Number(
                                            e.target.value
                                        ),
                                    },
                                }
                            })
                        }
                    />
                </div>
            </SinglePolicieContainer>
            <SinglePolicieContainer>
                <TitleDescription title="Permitir apenas reservas reembolsáveis" />
                <div className="inputs">
                    <ControlButton
                        id="interHotel.defaultRules.onlyRefundableHotels"
                        type="SWITCH"
                        isPressed={interHotel.defaultRules.onlyRefundableHotels}
                        callback={() => {
                            setInterHotel((prevState) => {
                                return {
                                    ...prevState,
                                    defaultRules: {
                                        ...prevState.defaultRules,
                                        onlyRefundableHotels:
                                            !prevState.defaultRules
                                                .onlyRefundableHotels,
                                    },
                                }
                            })
                        }}
                    />
                </div>
            </SinglePolicieContainer>
        </DropDownPolitic>
    )
}
