/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ButtonHTMLAttributes } from 'react'
import { IconType } from 'react-icons/lib'
import { FiLoader } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'
import { ButtonContainer } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string
    busy?: boolean
    buttonType: 'FILLED' | 'TEXT' | 'BORDERED'
    color: 'PRIMARY' | 'SECONDARY' | 'TERTIARY' | 'WARNING' | 'WHITE'
    icon?: IconType
    borderPrimary?: boolean
}

export function Button({
    text,
    busy,
    buttonType,
    color,
    borderPrimary,
    icon: Icon,
    ...rest
}: Props) {
    function returnStyle() {
        let colorTemp
        if (color === 'PRIMARY') {
            colorTemp = '#E74B70'
        }
        if (color === 'TERTIARY') {
            colorTemp = '#96989B'
        }
        if (color === 'WHITE') {
            colorTemp = '#FFF'
        }
        if (buttonType === 'FILLED') {
            return { backgroundColor: colorTemp, color: '#fff', border: 'none' }
        }
        if (buttonType === 'BORDERED') {
            return {
                backgroundColor: '#fff',
                color: colorTemp,
                border: `1px solid ${borderPrimary ? '#E74B70' : '#C8CACD'}`,
            }
        }
        if (buttonType === 'TEXT') {
            return {
                backgroundColor: 'inherit',
                color: colorTemp,
                border: 'none',
            }
        }
        return {}
    }

    return (
        <ButtonContainer {...rest} style={returnStyle()}>
            {Icon && <Icon />}
            {Icon && <div className="icon-space" />}
            {!busy ? (
                text
            ) : (
                <ClipLoader
                    color={`${
                        color !== 'PRIMARY' ||
                        (color === 'PRIMARY' && buttonType === 'BORDERED')
                            ? '#E74B70'
                            : 'white'
                    }`}
                    size={16}
                />
            )}
        </ButtonContainer>
    )
}
