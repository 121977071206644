/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'
import { MdArrowDropDown, MdEmail, MdPersonAdd, MdSearch } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { Header } from '../../components/Header'
// import { PillTabs } from '../../components/PillTabs'
import { SingleUser } from '../../components/SingleUser'
import { useCompany } from '../../hooks/use-company'
import { CreateNewUser } from '../../modals/CreateNewUser'
import { EditMember } from '../../modals/EditMember'
import { EditMemberProvider } from '../../modals/EditMember/hook/useEditMember'
import { InviteUser } from '../../modals/InviteUser'
import { Table } from '../../utils/table'
import { Filter, UsersContainer } from './styles'
import { ImportFile } from '../../modals/ImportFile'
import { PillTabs } from '../../components/PillTabs'
import { SelectInput } from '../../components/SelectInput'
import { FilterInput } from '../../components/FilterInput'
import { Button } from '../../components/Button'
import { SingleUserContainer } from '../../components/SingleUser/styles'
import { expensesDateFormatter } from '../../utils/expensnes/dateFormatter'
import { InviteStatus } from '../../enums/invite-status'
import { ControlButton } from '../../components/ControlButton'

export function UsersPage() {
    // hooks
    const {
        company,
        getMembers,
        members,
        exportUsers,
        filterMembers,
        getInvites,
        invites,
    } = useCompany()

    // state
    const [selectedSubMenu, setSelectedSubMenu] = useState<'USERS' | 'INVITES'>(
        'USERS'
    )
    const [busy, setBusy] = useState(false)
    const [openActions, setOpenActions] = useState(false)
    const [exportBusy, setExportBusy] = useState(false)
    const [filterByName, setFilterByName] = useState('')
    const [filterByTravelerClearence, setFilterByTravelerClearence] =
        useState('')
    const [openInviteUser, setOpenInviteUser] = useState(false)
    const [openEditMember, setOpenEditMember] = useState<string | undefined>()
    const [openCreateNewUser, setOpenCreateNewUser] = useState(false)
    const [openImportFile, setOpenImportFile] = useState<
        'USERS' | 'INVITE_USER' | 'OPTIONS' | 'MANAGEMENT_INFOS' | undefined
    >()
    const [onlyActiveUsers, setOnlyActiveUsers] = useState(true)
    const [isFirstLoad, setIsFirstLoad] = useState(true)

    function changeSubMenu(type: 'USERS' | 'INVITES') {
        setSelectedSubMenu(type)
    }

    function travelerClearenceOptions() {
        const options = company?.travelerClearances.map((t) => {
            return { label: t.label, value: t.id }
        })
        return [{ label: 'Todos', value: '' }, ...(options || [])]
    }

    useEffect(() => {
        setBusy(true)
        ;(async () => {
            if (company) {
                selectedSubMenu === 'USERS'
                    ? await getMembers(company.id)
                    : await getInvites(company.id)
            }
            setBusy(false)
        })()
    }, [selectedSubMenu])

    useEffect(() => {
        filterMembers({
            name: '',
            travelerClearanceId: '',
            isActive: onlyActiveUsers,
        })
    }, [selectedSubMenu])

    useEffect(() => {
        filterMembers({
            name: filterByName,
            travelerClearanceId: filterByTravelerClearence,
            isActive: onlyActiveUsers,
        })
    }, [filterByName, filterByTravelerClearence, onlyActiveUsers])

    return (
        <UsersContainer>
            <Header header="USERS" />
            <div className="sub-menu-container">
                <div className="sub-menu">
                    <PillTabs
                        changeTab={changeSubMenu}
                        value={selectedSubMenu}
                        theme="LIGHT"
                        pillTabs={[
                            { id: 'USERS', label: 'Usuários' },
                            {
                                id: 'INVITES',
                                label: 'Convites',
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="user-content">
                {selectedSubMenu === 'USERS' ? (
                    <div className="user-content--title">
                        <h1>Usuários</h1>
                        <p>{`${members.length} viajantes cadastrados`}</p>
                    </div>
                ) : (
                    <div className="user-content--title">
                        <h1>Convites</h1>
                        <p>{`${members.length} usuários convidados ainda não se cadastraram`}</p>
                    </div>
                )}
                <Filter>
                    {selectedSubMenu === 'USERS' ? (
                        <div>
                            <div className="flex">
                                <FilterInput
                                    name="name"
                                    label="Pesquisar..."
                                    value={filterByName}
                                    icon={MdSearch}
                                    onChange={(e) =>
                                        setFilterByName(e.target.value)
                                    }
                                />
                                <SelectInput
                                    name="travelerClearanceId"
                                    options={travelerClearenceOptions()}
                                    placeholder="Tipo de usuário"
                                    onChange={(e) =>
                                        setFilterByTravelerClearence(e!.value)
                                    }
                                />
                            </div>
                            <div className="user-switch">
                                <h1 className="f16-500-gray">
                                    Apenas usuários ativos
                                </h1>
                                <ControlButton
                                    type="SWITCH"
                                    isPressed={onlyActiveUsers}
                                    id="only-active-users"
                                    callback={() => {
                                        setOnlyActiveUsers(!onlyActiveUsers)
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <div />
                    )}
                    <div className="flex">
                        <div className="relative">
                            <button
                                type="button"
                                className="select-btn"
                                onClick={() => setOpenActions(!openActions)}
                            >
                                Ações em lote
                                <MdArrowDropDown />
                            </button>
                            {openActions && (
                                <div className="select-options">
                                    <button
                                        className="option-btn"
                                        type="button"
                                        onClick={() =>
                                            setOpenImportFile('INVITE_USER')
                                        }
                                    >
                                        Convidar em lote
                                    </button>
                                    <button
                                        className="option-btn"
                                        type="button"
                                        onClick={async () => {
                                            setOpenImportFile('USERS')
                                        }}
                                    >
                                        Ajustar usuários em lote
                                    </button>
                                    <button
                                        className="option-btn"
                                        type="button"
                                        onClick={async () => {
                                            setExportBusy(true)
                                            await exportUsers()
                                            setExportBusy(false)
                                        }}
                                    >
                                        {exportBusy ? (
                                            <ClipLoader size={16} />
                                        ) : (
                                            ' Exportar usuários'
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                        <Button
                            buttonType="BORDERED"
                            text="Adicionar"
                            color="PRIMARY"
                            icon={MdPersonAdd}
                            onClick={() => setOpenCreateNewUser(true)}
                        />
                        <Button
                            buttonType="FILLED"
                            text="Convidar"
                            color="PRIMARY"
                            icon={MdEmail}
                            onClick={() => setOpenInviteUser(true)}
                        />
                    </div>
                    {openActions && (
                        <div
                            className="div-opaciy"
                            onClick={() => setOpenActions(false)}
                        />
                    )}
                </Filter>
                {!busy ? (
                    <>
                        {selectedSubMenu === 'USERS' ? (
                            <Table>
                                <div
                                    className="table-header"
                                    style={{
                                        gridTemplateColumns: '3fr 2fr 2fr 2fr',
                                    }}
                                >
                                    <h1 className="start">Usuário</h1>
                                    <h1 className="start">Tipo de usuário</h1>
                                    <h1 className="start">
                                        Perfil de viajante
                                    </h1>
                                    <h1 className="start">Mais informações</h1>
                                </div>
                                {onlyActiveUsers
                                    ? members
                                          .filter(
                                              (m) =>
                                                  m.companyInfo[0].userIsActive
                                          )
                                          .map((member) => {
                                              return (
                                                  <SingleUser
                                                      member={member}
                                                      key={member.id}
                                                      onOpen={(id: string) =>
                                                          setOpenEditMember(id)
                                                      }
                                                  />
                                              )
                                          })
                                    : members.map((member) => {
                                          return (
                                              <SingleUser
                                                  member={member}
                                                  key={member.id}
                                                  onOpen={(id: string) =>
                                                      setOpenEditMember(id)
                                                  }
                                              />
                                          )
                                      })}
                            </Table>
                        ) : (
                            <Table>
                                <div
                                    className="table-header"
                                    style={{
                                        gridTemplateColumns: '1fr 1fr',
                                    }}
                                >
                                    <h1 className="start">Email</h1>
                                    <h1 className="start">Data do convite</h1>
                                </div>
                                {invites
                                    .filter(
                                        (invite) =>
                                            invite.status ===
                                            InviteStatus.PENDING
                                    )
                                    .map((invite) => {
                                        return (
                                            <SingleUserContainer
                                                style={{
                                                    gridTemplateColumns:
                                                        '1fr 1fr',
                                                }}
                                            >
                                                <div
                                                    className="item start"
                                                    style={{
                                                        padding: '2rem',
                                                    }}
                                                >
                                                    <p className="f16-500-gray">
                                                        {invite.email}
                                                    </p>
                                                </div>
                                                <div
                                                    className="item start"
                                                    style={{
                                                        padding: '2rem',
                                                    }}
                                                >
                                                    <p className="f16-500-gray">
                                                        {expensesDateFormatter(
                                                            invite.createdAt
                                                        )}
                                                    </p>
                                                </div>
                                            </SingleUserContainer>
                                        )
                                    })}
                            </Table>
                        )}
                    </>
                ) : (
                    <div />
                )}
            </div>
            <InviteUser
                isOpen={openInviteUser}
                onRequestClose={() => setOpenInviteUser(false)}
            />
            <CreateNewUser
                isOpen={openCreateNewUser}
                onRequestClose={() => setOpenCreateNewUser(false)}
            />
            {openEditMember && (
                <EditMemberProvider id={openEditMember}>
                    <EditMember
                        isOpen={!!openEditMember}
                        onRequestClose={() => setOpenEditMember(undefined)}
                    />
                </EditMemberProvider>
            )}
            {openImportFile && (
                <ImportFile
                    isOpen={!!openImportFile}
                    onRequestClose={() => setOpenImportFile(undefined)}
                    type={openImportFile}
                />
            )}
        </UsersContainer>
    )
}
