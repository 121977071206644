import React from 'react'
import { BrokenPoliciesReasonContainer } from './styles'
import { ExpensesCompliance } from '../../interfaces/expensesCompliance'

interface Props {
    policyBreachesByExpenseCategory: ExpensesCompliance['response']['policyBreachesByExpenseCategory']
}

export function ExpensesBrokenPoliciesReason({
    policyBreachesByExpenseCategory,
}: Props) {
    return (
        <BrokenPoliciesReasonContainer>
            <div className="broken-title">
                <h1 className="f16-700-dark">Motivos de quebra de política</h1>
            </div>
            <div className="label-values-title">
                <h4 className="f14-500-gray">Categoria</h4>
                <div className="values">
                    <h4 className="f14-500-gray">Percentual</h4>
                    <div className="divider" />
                    <h4 className="f14-500-gray">Quantidade</h4>
                    <div className="divider" />
                    <h4 className="f14-500-gray">Total</h4>
                </div>
            </div>
            {policyBreachesByExpenseCategory.map((item) => (
                <div className="progressive-bar-container">
                    <div className="label-values">
                        <h4 className="f14-500-gray">
                            {item.expenseCategoryLabel}
                        </h4>
                        <div className="values">
                            <h4 className="f14-500-gray">
                                {item.singleItemBreaches.percentage.toFixed(2)}%
                            </h4>
                            <div className="divider" />
                            <h4 className="f14-500-gray">
                                {item.singleItemBreaches.countOfBreachs}
                            </h4>
                            <div className="divider" />
                            <h4 className="f14-500-gray">
                                {item.singleItemBreaches?.amount
                                    ? item.singleItemBreaches.amount.toLocaleString(
                                          'pt-BR',
                                          { style: 'currency', currency: 'BRL' }
                                      )
                                    : 'R$ 0,00'}
                            </h4>
                        </div>
                    </div>
                    <div className="progressive-bar-label">
                        <div className="progressive-bar">
                            <div
                                className="filled"
                                style={{
                                    width: `${item.singleItemBreaches.percentage}%`,
                                }}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </BrokenPoliciesReasonContainer>
    )
}
